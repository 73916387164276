import React, {Component} from 'react';

import $ from 'jquery';
import {TweenMax} from 'gsap';
import moment from 'moment';


import flashVid from './assets/vid/flash.mp4';
import flashVid2 from './assets/vid/flash2.mp4';

import superVid from './assets/vid/supergirl.mp4';
import superVid2 from './assets/vid/supergirl2.mp4';



class App extends Component {
 constructor () {
  super ();
  this.state = {
   page: 'Home',
   countDownHour : 0,
   countDownMin : 0,
   countDownSec : 0,
   countdownDate: 'Oct 23, 2019 21:50:00',
   nowShowDate: 'Oct 23, 2019 22:30:00',
   startDate2: 'Oct 24, 2019 21:50:00',
   countDownDate2 : 'Nov 6, 2019 21:50:00',
   nowShowDate2: 'Nov 6, 2019 22:30:00',
   isNowShowing : false,
   isNowShowing2 : false,
   showSupergirl: false
 };

}
time = 0;

ref = player => {
 this.player = player
}

componentDidMount () {

 this.handleEvent('Initialize Panel', 'Home');



 document.addEventListener ('contextmenu', event => event.preventDefault ());
 let _this = this;
 let search = window.location.search;
 let params = new URLSearchParams(search);

 let countDownDate = params.get('date1');
 let nowShowDate = params.get('nsd1');
 // let 4 = params.get('date2');
 // let nowShowDate2 = params.get('nsd2');
 let showSupergirl = params.get('ss');
 let days = 0,hours = 0,minutes = 0,seconds = 0;

 if (showSupergirl == 1)
 {
  const video = document.createElement('video');
  video.autoplay = false;
  video.loop = true;
  video.muted = true; 
  video.setAttribute('playsinline', 'true');

  const source = document.createElement('source');
  source.src = superVid;
  source.type = 'video/mp4';
  video.appendChild(source);

  this.videoContainer.appendChild(video);

  const video2 = document.createElement('video');
  video2.autoplay = true;
  video2.loop = true;
  video2.muted = true; 
  video2.setAttribute('playsinline', 'true'); 

  const source2 = document.createElement('source');
  source2.src = superVid2;
  source2.type = 'video/mp4';
  video2.appendChild(source2);

  this.videoContainer2.appendChild(video2);


   if (countDownDate != null){
   countDownDate = moment(countDownDate,'DD/MM/YYYYhh:mm:ss');
 }
 else
 {
  countDownDate = moment('28/10/201921:50:00','DD/MM/YYYYhh:mm:ss');
  
}

if (nowShowDate != null){
 nowShowDate = moment(nowShowDate,'DD/MM/YYYYhh:mm:ss');
}
else
{
  nowShowDate = moment('28/10/201921:50:00','DD/MM/YYYYhh:mm:ss');
}


}
else {
  const video = document.createElement('video');
  video.autoplay = false;
  video.loop = true;
  video.muted = true; 
  video.setAttribute('playsinline', 'true');

  const source = document.createElement('source');
  source.src = flashVid;
  source.type = 'video/mp4';
  video.appendChild(source);

  this.videoContainer.appendChild(video);

  const video2 = document.createElement('video');
  video2.autoplay = true;
  video2.loop = true;
  video2.muted = true; 
  video2.setAttribute('playsinline', 'true'); 

  const source2 = document.createElement('source');
  source2.src = flashVid2;
  source2.type = 'video/mp4';
  video2.appendChild(source2);

  this.videoContainer2.appendChild(video2);

  if (countDownDate != null){
   countDownDate = moment(countDownDate,'DD/MM/YYYYhh:mm:ss');
 }
 else
 {
  countDownDate = moment('23/10/201921:50:00','DD/MM/YYYYhh:mm:ss');
  
}

if (nowShowDate != null){
 nowShowDate = moment(nowShowDate,'DD/MM/YYYYhh:mm:ss');
}
else
{
  nowShowDate = moment('23/10/201921:50:00','DD/MM/YYYYhh:mm:ss');
}
}

this.setState({
  isSuperGirl : showSupergirl,
  countdownDate : countDownDate,
  nowShowDate : nowShowDate,
  // countDownDate2 : countDownDate2,
  // nowShowDate2 : nowShowDate2
});



var bigTimer = setInterval( () => {

         // Get today's date and time
         //var now = new Date().getTime();
         var now = moment(new Date());
         
         if (moment(now).isAfter(nowShowDate))
         {
          console.log("adding")

          this.setState({isNowShowing : false});
          countDownDate = countDownDate.add('7','day');
          nowShowDate = nowShowDate.add('7','day');

        }
        else if (moment(now).isAfter(countDownDate, 'second') 
          && moment(now).isBefore(nowShowDate, 'second'))
        {
          this.setState({isNowShowing : true});
          days = 0;
          hours = 0;
          minutes = 0;
          seconds = 0;

          //clearInterval(bigTimer);
        }
        else {
          this.setState({isNowShowing : false});
        }

        var days = countDownDate.diff(now, 'days')
        if (days < 10)
          days = "0" + days;
        var hours = countDownDate.diff(now, 'hours') % 24
        if (hours < 10)
          hours = "0" + hours;
        var minutes = countDownDate.diff(now, 'minutes') % 60
        if (minutes < 10)
          minutes = "0" + minutes;
        var seconds = countDownDate.diff(now, 'seconds') % 60
        if (seconds < 10)
          seconds = "0" + seconds;

        this.setState({
          countDownDays : days,
          countDownHour : hours,
          countDownMin : minutes,
          countDownSec : seconds
        })

      }, 1000)

}

componentDidUpdate() {

}

countTitleClicks = () => {
  var count = 0;
  if (this.state.br1)
   count++;
 if (this.state.br2)
   count++;
 if (this.state.br3)
   count++;
 if (this.state.br4)
   count++;
 return count;
}



handleChangeNextPage = (a, b, page = '', click = '', next = '') => {
  console.log("handle change next page")
  TweenMax.to (a, 0.5, {opacity: 1, display: 'none'});
  TweenMax.to (b, 0.5, {display: 'block'});
  if (page) {
   this.handleEvent(`${click} Button Click`, page);
 }
 if (next) {
   this.setState ({page: next});
 }
 this.time = 0;
};

handleChangePrevPage = (a, b, page = '', click = '', next = '') => {
  console.log("handle change prev page")
      //TweenMax.to (a, 0.5, {left: '+100%', display: 'none'});
      TweenMax.to (a, 0.5, {left: '0', display: 'none'});
      TweenMax.fromTo (
       b,
       0.5,
       {opacity: 0},
       {left: '0%', opacity: 1, display: 'block'}
       );
      if (page) {
       this.handleEvent (`${click} Button Click`, page);
     }
     if (next) {
       this.setState ({page: next});
     }
     this.time = 0;
   };
   handleChangeAniPage = (a, b, page, click, next) => {
    console.log("handle change ani page")
    TweenMax.to (a, 0.5, {display: 'none'});
    TweenMax.fromTo (
     b,
     0.5,
     {opacity: 0, left: '0%', display: 'block'},
     {opacity: 1}
     );
    setTimeout (() => {
     TweenMax.set (a, {left: '+100%', opacity: 1});
   }, 1000);
    this.handleEvent (`${click} Button Click`, page);
    this.setState ({page: next});
    this.time = 0;
  };
  handleChangePage = (a, b, page, click, next) => {
    console.log("handle change page")
    TweenMax.set (a, {display: 'none'});
    TweenMax.set (b, {opacity: 1, display: 'block', left: '0'});
    setTimeout (() => {
     TweenMax.set (a, {left: '-100%', opacity: 1});
   }, 1000);
    this.handleEvent (`${click} Button Click`, page);
    this.setState ({page: next});
    this.time = 0;
  };

  handleStartCapture = () => {
    let _this = this;
    setTimeout (() => {
     const imageSrc = _this.camera.getScreenshot ();
     let c = document.getElementById ('myCanvas2');
     let ctx = c.getContext ('2d');

     let imageObj = new Image ();
     imageObj.src = imageSrc;
     imageObj.onload = function () {
      ctx.translate (900, 0);
      ctx.scale (-1, 1);
      ctx.drawImage (imageObj, -140, 0);

      let newImg = c.toDataURL ('image/jpeg');
      _this.setState ({
       imageSrc: newImg,
     });
      ctx.translate (900, 0);
      ctx.scale (-1, 1);
    };
    _this.handleChangeNextPage ('#capture-page','#sticker-page','','','Sticker');
         //   }, 6000); // for production
       }, 1000);
    setTimeout (() => {
     this.audio_shutter.play();
         //   }, 5700) // for production
       }, 800);

    this.countTimer ();
  };
  countTimer = () => {
    let _this = this;
    setTimeout (() => {
     _this.setState ({timer_number: 5});
     TweenMax.to ('#timer-img, #timer-number', 0.3, {opacity: 1});
     TweenMax.to ('#timer-img, #timer-number', 0.2, {opacity: 0, delay: 0.7});
     setTimeout (() => {
      _this.setState ({timer_number: 4});
      TweenMax.to ('#timer-img, #timer-number', 0.3, {opacity: 1});
      TweenMax.to ('#timer-img, #timer-number', 0.2, {
       opacity: 0,
       delay: 0.7,
     });
      setTimeout (() => {
       _this.setState ({timer_number: 3});
       TweenMax.to ('#timer-img, #timer-number', 0.3, {opacity: 1});
       TweenMax.to ('#timer-img, #timer-number', 0.2, {
        opacity: 0,
        delay: 0.7,
      });
       setTimeout (() => {
        _this.setState ({timer_number: 2});
        TweenMax.to ('#timer-img, #timer-number', 0.3, {opacity: 1});
        TweenMax.to ('#timer-img, #timer-number', 0.2, {
         opacity: 0,
         delay: 0.7,
       });
        setTimeout (() => {
         _this.setState ({timer_number: 1});
         TweenMax.to ('#timer-img, #timer-number', 0.3, {opacity: 1});
         TweenMax.to ('#timer-img, #timer-number', 0.2, {
          opacity: 0,
          delay: 0.7,
        });
       }, 1000);
      }, 1000);
     }, 1000);
    }, 1000);
   }, 500);
  };

  handleEvent = (label, page, extra = '') => {
    // const panel = this.getQueryStringValue('panel')
    // extra += 'Option ' + this.state.panelOption;
    // let data = new FormData()
    // data.append("action", 'update_count')
    // data.append("label", label)
    // data.append("frame", page + ' Page')
    // data.append("panel", panel)
    // data.append("add_option", extra)
    // fetch("http://ncss.wunderfauks.com/wp/wp-admin/admin-ajax.php", {
    //    method: "POST",
    //    body: data,
    // })
    // .then(response => response.json())
    // .then( result => {})
  };
  getQueryStringValue = key => {
   return decodeURIComponent (
    window.location.search.replace (
     new RegExp ('^(?:.*[&\\?]' +encodeURIComponent (key).replace (/[\.\+\*]/g, '\\$&') +'(?:\\=([^&]*))?)?.*$','i'),'$1'
     )
    );
 };

 handleAllRestart = () => {
  console.log("handle all restart")
  TweenMax.to ('#i-thank-page', 0.3, {opacity: 0});
  this.handleEvent (`Back to Home Button Click`, 'Thank you');
  let _this = this;
  setTimeout (() => {
   _this.handleRestart ();
 }, 500);
  this.time = 0;
};

renderCountdown = () => {
  const {
   countDownDays,
   countDownHour,
   countDownSec,
   countDownMin,
 } = this.state;
 if (countDownDays != 0)
 {
   return(
     <div className="timer-cont days">
     <div className="cd-val cd-days">{countDownDays}</div>
     <div className="cd-val cd-hour">{countDownHour}</div>
     <div className="cd-val cd-min">{countDownMin}</div>
     <div className="cd-lbl1">Days</div>
     <div className="cd-lbl2">Hours</div>
     <div className="cd-lbl3">Mins</div>
     </div>
     )
   }
   else{
     return(
     <div className="timer-cont">
     <div className="cd-val cd-hour">{countDownHour}</div>
     <div className="cd-val cd-min">{countDownMin}</div>
     <div className="cd-val cd-sec">{countDownSec}</div>
     <div className="cd-lbl1">Hours</div>
     <div className="cd-lbl2">Mins</div>
     <div className="cd-lbl3">Secs</div>
     </div>
     )
   }
 }

 render () {
  const {
   isNowShowing,
   isSuperGirl
 } = this.state;
 return (
 <div id="app">

 <div id="home-page" className="pages">
 <div id="background-video" ref={(ref) => { this.videoContainer = ref; }}  className={(isNowShowing ? "inactive" : "")}/> 
 <div className={"countdown-bg " + (isNowShowing ? "inactive " : "") + (isSuperGirl ? "ss" : "")}>
 {this.renderCountdown()}
 </div>
 <div className={"nowShowing " + (isNowShowing ? "active" : "")} ref={(ref) => { this.videoContainer2 = ref; }}>

 </div>
 </div>

 </div>

 );
}

handleKey = (e) => {
  this.time = 0
  const {email, caret} = this.state
  let text = e.currentTarget.dataset.id
  let emailText = ''
  if(caret === email.length ){
   emailText = email + text
   this.setState({caret: this.email.selectionStart + text.length})
 }else{
   emailText = email.slice(0,caret) + text + email.slice(caret, email.length)
   this.setState({caret: caret + text.length})
 }
 let validated = this.validateEmail(emailText)
 this.setState({
   email: emailText,
   emailV: validated
 })

}
validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(String(email).toLowerCase())
 }
 getQueryStringValue =  (key) => {
  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"))
}


}

export default App;
